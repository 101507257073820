import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { Button, Typography, IconButton, Checkbox } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import { useGetOrganizationForUI } from '@api/queries/organization';
import { useToggle } from '@hooks';
import SimpleTable from '@components/table/simple';
import { TableEmptyState } from '@components/styled/state';
import AddDiscountModal from '@features/customer/modal/discount/add';
import EditDiscountModal from '@features/customer/modal/discount/edit';
import DeleteDiscountModal from '@features/customer/modal/discount/delete';
import { formatLocaleString } from '@utils/formatter.ts';

export default function CustomerDiscount() {
  const { t } = useTranslation('table');
  const { t: customerT } = useTranslation('customer');
  const [openAdd, toggleAdd] = useToggle();
  const [toEdit, setToEdit] = useState<number | undefined>(undefined);
  const [toDelete, setToDelete] = useState<number | undefined>(undefined);
  const { data: response } = useGetOrganizationForUI();
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<AdditionalService>();
    return [
      columnHelper.accessor('ServiceName', {
        id: 'ServiceName',
        header: t('customer.discountName'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('ApplyLevel', {
        id: 'ApplyLevel',
        header: t('customer.applyLevel'),
        cell: (v) => {
          switch (v.getValue()) {
            case 'Company':
              return customerT('form.service.label.companyLevel');
            case 'Account':
              return customerT('form.service.label.accountLevel');
            default:
              return '-';
          }
        },
      }),
      columnHelper.display({
        id: 'AWS',
        header: 'AWS',
        cell: (v) => (
          <Checkbox
            checked={v.row.original.Providers.includes('aws')}
            disabled
          />
        ),
        meta: {
          align: 'center',
        },
      }),
      columnHelper.display({
        id: 'NCP',
        header: 'Naver Cloud',
        cell: (v) => (
          <Checkbox
            checked={v.row.original.Providers.includes('ncp')}
            disabled
          />
        ),
        meta: {
          align: 'center',
        },
      }),
      columnHelper.display({
        id: 'NHN',
        header: 'NHN Cloud',
        cell: (v) => (
          <Checkbox
            checked={v.row.original.Providers.includes('nhn')}
            disabled
          />
        ),
        meta: {
          align: 'center',
        },
      }),
      columnHelper.display({
        id: 'BillingMethod',
        header: t('customer.billingMethod'),
        cell: (v) => {
          switch (v.row.original.PricingUnit) {
            case 'PCT':
              return customerT('table.text.billingRatio');
            case 'KRW':
            case 'USD':
              return customerT('table.text.billingFixed');
            default:
              return '-';
          }
        },
      }),
      columnHelper.accessor('UsageAmount', {
        id: 'UsageAmount',
        header: t('customer.usageAmount'),
        cell: (v) => formatLocaleString(v.getValue()),
      }),
      columnHelper.accessor('Cost', {
        id: 'Cost',
        header: t('customer.cost'),
        cell: (v) => formatLocaleString(v.getValue()),
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('PricingUnit', {
        id: 'PricingUnit',
        header: t('customer.pricingUnit'),
        cell: (v) => {
          switch (v.getValue()) {
            case 'PCT':
              return '%';
            default:
              return v.getValue();
          }
        },
      }),
      columnHelper.display({
        id: 'Delete',
        header: t('customer.deleteDiscount'),
        cell: (v) => (
          <IconButton
            size="small"
            color="danger"
            onClick={() => setToDelete(v.row.index)}
          >
            <Delete />
          </IconButton>
        ),
        meta: {
          align: 'center',
        },
      }),
      columnHelper.display({
        id: 'Edit',
        header: t('general.edit'),
        cell: (v) => (
          <IconButton
            size="small"
            color="calm"
            onClick={() => setToEdit(v.row.index)}
          >
            <Edit />
          </IconButton>
        ),
        meta: {
          align: 'center',
        },
      }),
    ];
  }, [t, customerT]);
  return (
    <>
      <SimpleTable
        columns={columns}
        data={response?.data.BillingCustom.Discounts}
        title={customerT('title.discount')}
        emptyState={
          <TableEmptyState sx={{ gap: '32px' }}>
            <Typography color="text.main">
              {customerT('table.empty.discount')}
            </Typography>
            <Button color="sub" onClick={toggleAdd} startIcon={<Add />}>
              {customerT('button.addDiscount')}
            </Button>
          </TableEmptyState>
        }
      >
        <Button onClick={toggleAdd} startIcon={<Add />}>
          {customerT('button.addDiscount')}
        </Button>
      </SimpleTable>
      <AddDiscountModal
        open={openAdd}
        onClose={toggleAdd}
        item={response?.data.BillingCustom}
      />
      <EditDiscountModal
        open={typeof toEdit !== 'undefined'}
        onClose={() => setToEdit(undefined)}
        item={response?.data?.BillingCustom}
        index={toEdit}
      />
      <DeleteDiscountModal
        open={typeof toDelete !== 'undefined'}
        onClose={() => setToDelete(undefined)}
        item={response?.data?.BillingCustom}
        index={toDelete}
      />
    </>
  );
}
