import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import SimpleTable from '@components/table/simple';
import { CloudTypeChip } from '@components/styled/chip';
import { formatKRW, formatUSD } from '@utils/formatter';

type InvoiceForm = {
  AccountId: string;
  CloudType?: NCPAccountType;
  OriginCost?: number;
  CloudDiscount?: number;
  CloudCost?: number;
  AdditionalCost: number;
  DiscountCost: number;
  TotalCost: number;
};

type Props = {
  Invoices: Array<Invoice>;
};
export default function InvoiceDetail({ Invoices }: Props) {
  const { t } = useTranslation('table', { keyPrefix: 'invoice' });
  const { t: invoiceT } = useTranslation('invoice');
  const awsInvoiceData = useMemo(() => {
    const awsInvoices = Invoices.filter(
      (v) => v.CSP === 'aws',
    ) as Array<AWSInvoice>;
    if (!awsInvoices.length) {
      return [];
    }
    const result: Array<InvoiceForm> = awsInvoices.map((v) => ({
      AccountId: v.AccountId,
      OriginCost: v.CloudCost.USD,
      CloudCost: v.CloudCost.KRW,
      AdditionalCost: v.AdditionalCost.KRW,
      DiscountCost: v.DiscountCost.KRW,
      TotalCost: v.TotalCost.KRW,
    }));
    result.unshift({
      AccountId: invoiceT('text.invoiceCommonTitle'),
      AdditionalCost: awsInvoices[0].OrgAdditionalCost.KRW,
      DiscountCost: awsInvoices[0].OrgDiscountCost.KRW,
      TotalCost:
        awsInvoices[0].OrgAdditionalCost.KRW -
        awsInvoices[0].OrgDiscountCost.KRW,
    });
    return result;
  }, [Invoices, invoiceT]);
  const ncpInvoiceData = useMemo(() => {
    const ncpInvoices = Invoices.filter(
      (v) => v.CSP === 'ncp',
    ) as Array<NCPInvoice>;
    if (!ncpInvoices.length) {
      return [];
    }
    const result: Array<InvoiceForm> = ncpInvoices.map((v) => ({
      AccountId: v.AccountId,
      CloudType: v.CloudType,
      CloudCost: v.CloudCost.KRW,
      AdditionalCost: v.AdditionalCost.KRW,
      DiscountCost: v.DiscountCost.KRW,
      TotalCost: v.TotalCost.KRW,
    }));
    result.unshift({
      AccountId: invoiceT('text.invoiceCommonTitle'),
      AdditionalCost: ncpInvoices[0].OrgAdditionalCost.KRW,
      DiscountCost: ncpInvoices[0].OrgDiscountCost.KRW,
      TotalCost:
        ncpInvoices[0].OrgAdditionalCost.KRW -
        ncpInvoices[0].OrgDiscountCost.KRW,
    });
    return result;
  }, [Invoices, invoiceT]);
  const nhnInvoiceData = useMemo(() => {
    const nhnInvoices = Invoices.filter(
      (v) => v.CSP === 'nhn',
    ) as Array<NHNInvoice>;
    if (!nhnInvoices.length) {
      return [];
    }
    const result: Array<InvoiceForm> = nhnInvoices.map((v) => ({
      AccountId: v.OriginData.ProjectName,
      OriginCost: v.OriginData.UsagePrice,
      CloudDiscount: v.OriginData.UsagePrice - v.CloudCost.KRW,
      CloudCost: v.CloudCost.KRW,
      AdditionalCost: v.AdditionalCost.KRW,
      DiscountCost: v.DiscountCost.KRW,
      TotalCost: v.TotalCost.KRW,
    }));
    result.unshift({
      AccountId: invoiceT('text.invoiceCommonTitle'),
      AdditionalCost: nhnInvoices[0].OrgAdditionalCost.KRW,
      DiscountCost: nhnInvoices[0].OrgDiscountCost.KRW,
      TotalCost:
        nhnInvoices[0].OrgAdditionalCost.KRW -
        nhnInvoices[0].OrgDiscountCost.KRW,
    });
    return result;
  }, [Invoices, invoiceT]);
  const awsColumns = useMemo(() => {
    const columnHelper = createColumnHelper<InvoiceForm>();
    return [
      columnHelper.accessor('AccountId', {
        id: 'AWSAccountId',
        header: t('account'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('OriginCost', {
        id: 'AWSUSDCloudCost',
        header: t('cloudUsage'),
        cell: (v) => formatUSD(v.getValue()) ?? '-',
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('CloudCost', {
        id: 'AWSKRWCloudCost',
        header: t('cloudUsage'),
        cell: (v) => formatKRW(v.getValue()) ?? '-',
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('AdditionalCost', {
        id: 'AWSKRWAdditionalCost',
        header: t('serviceCost'),
        cell: (v) => formatKRW(v.getValue()),
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('DiscountCost', {
        id: 'AWSKRWDiscountCost',
        header: t('discountCost'),
        cell: (v) => formatKRW(v.getValue()),
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('TotalCost', {
        id: 'AWSKRWTotalCost',
        header: t('totalCost'),
        cell: (v) => formatKRW(v.getValue()),
        meta: {
          align: 'right',
        },
      }),
    ];
  }, [t]);
  const ncpColumns = useMemo(() => {
    const columnHelper = createColumnHelper<InvoiceForm>();
    return [
      columnHelper.accessor('AccountId', {
        id: 'NCPAccountId',
        header: t('ncp.account'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('CloudType', {
        id: 'NCPCloudType',
        header: t('ncp.cloudType'),
        cell: (v) => {
          if (v.getValue()) {
            return <CloudTypeChip type={v.getValue() as NCPAccountType} />;
          }
          return '-';
        },
      }),
      columnHelper.accessor('CloudCost', {
        id: 'NCPKRWCloudCost',
        header: t('cloudUsage'),
        cell: (v) => formatKRW(v.getValue()) ?? '-',
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('AdditionalCost', {
        id: 'NCPKRWAdditionalCost',
        header: t('serviceCost'),
        cell: (v) => formatKRW(v.getValue()),
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('DiscountCost', {
        id: 'NCPKRWDiscountCost',
        header: t('discountCost'),
        cell: (v) => formatKRW(v.getValue()),
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('TotalCost', {
        id: 'NCPKRWTotalCost',
        header: t('totalCost'),
        cell: (v) => formatKRW(v.getValue()),
        meta: {
          align: 'right',
        },
      }),
    ];
  }, [t]);
  const nhnColumns = useMemo(() => {
    const columnHelper = createColumnHelper<InvoiceForm>();
    return [
      columnHelper.accessor('AccountId', {
        id: 'NHNAccountId',
        header: t('account'),
        cell: (v) => v.getValue(),
      }),
      columnHelper.accessor('OriginCost', {
        id: 'NHNOriginalAmount',
        header: t('nhn.originalAmount'),
        cell: (v) => formatKRW(v.getValue()) ?? '-',
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('CloudDiscount', {
        id: 'NHNCloudDiscount',
        header: t('nhn.cloudDiscount'),
        cell: (v) => formatKRW(v.getValue()) ?? '-',
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('CloudCost', {
        id: 'NHNKRWCloudCost',
        header: t('nhn.purchaseAmount'),
        cell: (v) => formatKRW(v.getValue()) ?? '-',
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('AdditionalCost', {
        id: 'NHNKRWAdditionalCost',
        header: t('serviceCost'),
        cell: (v) => formatKRW(v.getValue()),
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('DiscountCost', {
        id: 'NHNKRWDiscountCost',
        header: t('discountCost'),
        cell: (v) => formatKRW(v.getValue()),
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('TotalCost', {
        id: 'NHNKRWTotalCost',
        header: t('totalCost'),
        cell: (v) => formatKRW(v.getValue()),
        meta: {
          align: 'right',
        },
      }),
    ];
  }, [t]);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '56px' }}>
      {Boolean(awsInvoiceData.length) && (
        <SimpleTable
          columns={awsColumns}
          data={awsInvoiceData}
          title={invoiceT('text.awsChargeInformation')}
        />
      )}
      {Boolean(ncpInvoiceData.length) && (
        <SimpleTable
          columns={ncpColumns}
          data={ncpInvoiceData}
          title={invoiceT('text.ncpChargeInformation')}
        />
      )}
      {Boolean(nhnInvoiceData.length) && (
        <SimpleTable
          columns={nhnColumns}
          data={nhnInvoiceData}
          title={invoiceT('text.nhnChargeInformation')}
        />
      )}
    </Box>
  );
}
